.filter-btn:hover {
  background-color: #58277d;
  color: white;
}

.filter-btn {
  height: 50px;
  width: 100px;
  background-color: white;
  color: #58277d;
  border: 0;
  border-radius: 5px;
  font-family: "Source Sans Pro", system-ui, sans-serif;
  cursor: pointer;
}

.settings {
  margin-top: 30px;
}

.settings-btn {
  margin-top: 30px;
}

.button-table {
  margin-left: 300px;
  margin-bottom: 1%;
}
